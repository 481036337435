import './App.css';
import Home from './Home/Home';

function App() {
    return (
        <div className='page'>
            <Home />
        </div>
    );
}

export default App;